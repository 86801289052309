import { useState } from 'react';
import NextLink from 'next/link';

import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  HttpError,
  HttpStatus,
  LocalStorage,
} from '@2ndmarket/helpers';
import { userRequestToken } from '@2ndmarket/services';
import { FormLoginEmailProps } from '@2ndmarket/types';
import { useLocalStorage } from '@2ndmarket/hooks';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Loader from '../Loader';
import Copyright from '../Copyright';
import loginStep from './loginStep';
import ModalDialog from '../ModalDialog';
import AppReference from '../AppReference';

import { schema, defaultValues } from './formInfo';

type FormValues = {
  email: string;
};

const EmailStep: React.FC<FormLoginEmailProps> = ({
  setStep,
  setEmail,
  email,
}) => {
  const [userId, setUserId] = useLocalStorage(
    LocalStorage.USER_ID,
    '',
  );

  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const typedEmail = useWatch({ control, name: 'email' });

  const changeProduct = () => {
    setStep(loginStep.chooseProduct);
    setEmail(typedEmail);
  };

  const handleError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setShowErrorModal(true);
      setErrorModalMessage(error.error);
    }

    setLoading(false);
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    setEmail(data.email.toLowerCase());

    userRequestToken(data.email.toLowerCase())
      .then((data: { id: string }) => {
        setUserId(data.id);
        setStep(loginStep.providePassword);
      })
      .catch((error: HttpError) => {
        handleError(error);
      });
  };

  return (
    <Grid
      item
      md={5}
      sm={9}
      xs={12}
      height="100%"
      paddingTop={8}
      display="flex"
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ backgroundColor: 'background.neutral' }}
    >
      {loading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Loader
            loaderState={loading}
            text="Enviando código..."
          />
        </Box>
      ) : (
        <Box paddingX={3}>
          <Typography
            variant="h2"
            component="h2"
            align="center"
            color="text.primary"
          >
            Login
          </Typography>
          <Typography
            paddingY={4}
            component="p"
            variant="body1"
            color="text.secondary"
          >
            Selecione abaixo o produto que deseja realizar login.
            Em seguida, digite o e-mail utilizado para realizar
            seu cadastro que enviaremos um código de acesso:
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <AppReference
              childToParentDrawer={() => changeProduct}
            />
            <TextField
              {...register('email', { required: true })}
              fullWidth
              autoFocus
              name="email"
              label="E-mail *"
              margin="normal"
              variant="outlined"
              sx={{ borderWidth: 1 }}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              inputProps={{
                style: { textTransform: 'lowercase' },
                maxLength: 75,
              }}
            />
            <Grid container marginTop={1}>
              <Grid item xs></Grid>
              <Grid item>
                <Typography component="p" variant="body1">
                  <NextLink href="/recuperar-email" passHref>
                    <Link color="primary.main">
                      Esqueci o e-mail
                    </Link>
                  </NextLink>
                </Typography>
              </Grid>
            </Grid>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              sx={{ marginTop: 4, marginBottom: 4 }}
            >
              Enviar código
            </Button>
            <Typography
              padding={2}
              component="p"
              align="center"
              variant="body1"
              color="text.secondary"
            >
              Novo por aqui?{' '}
              <NextLink href="/cadastro" passHref>
                <Link color="primary.main">Crie sua conta</Link>
              </NextLink>
            </Typography>
          </Box>
        </Box>
      )}

      <Copyright />

      {showErrorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={showErrorModal}
          errorMessage={errorModalMessage}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </Grid>
  );
};

export default EmailStep;
