import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ListItemButton from '@mui/material/ListItemButton';

import { themes } from '@2ndmarket/components';

export const ButtonProduct = styled(Button)({
  '&.Mui-disabled': {
    width: 93,
    height: 30,
    fontSize: 14,
    fontWeight: 400,
    boxShadow: 'none',
    color: themes.authentication.palette.grey[300],
    backgroundColor: themes.authentication.palette.grey[400],
    '&:hover': {
      cursor: 'default',
      backgroundColor: themes.authentication.palette.grey[400],
    },
  },
});

export const CheckboxProduct = styled(Checkbox)({
  display: 'none',
  '&.MuiCheckbox-root': {
    color: themes.authentication.palette.grey[200],
  },
  '&.Mui-checked': {
    color: themes.authentication.palette.primary.main,
  },
});

export const ListItemButtonProduct = styled(ListItemButton)({
  gap: 8,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignContent: 'center',
  padding: '11px 25px',
});
