import React from 'react';
import { Icon, themes } from '@2ndmarket/components';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import Products from '../../helper/products';
import useResponsive from '../../hooks/useResponsive';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';

enum Texts {
  DEFAULT_TEXT = 'Acesse o nosso ecossitema e obtenha uma nova e melhor experiência com os seus ativos: conta digital, carteira cripto, rentabilidade e muito mais.',
  ACCESS_TEXT = 'Aguarde enquanto carregamos as suas informações...',
  ACCESS_TITLE = 'Fique tranquilo: você está navegando pelo nosso ecossistema...',
}

interface RoutineProps {
  removeBackground?: any;
  full?: boolean;
  access?: boolean;
}

const Routine: React.FC<RoutineProps> = ({
  removeBackground,
  full,
  access,
}) => {
  const mediumUp = useResponsive('up', 'md', 'sm');
  removeBackground = useResponsive('up', 'sm', 'xs');

  return (
    <Grid
      item
      md={full ? 12 : 7}
      sm={full ? 12 : 3}
      xs={full ? 12 : false}
      paddingX="9%"
      paddingY={8}
      sx={{
        backgroundImage: 'url(/background.mui.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: {
          sm: 'block',
          xs: `${full ? 'block' : 'none'}`,
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Box sx={{ alignSelf: 'start' }}>
          <Icon
            size={28}
            name="logo-2nd"
            format={IconFormat.SVG}
            color={themes.authentication.palette.common.white}
          />
        </Box>
        {(mediumUp || access) && (
          <Box
            sx={{
              maxWidth: '520px',
              mx: 'auto',
              alignSelf: 'center',
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              marginTop={5}
              marginBottom={5}
              color={themes.authentication.palette.common.white}
            >
              {access ? (
                Texts.ACCESS_TITLE
              ) : (
                <>
                  Acrescente na sua rotina +{' '}
                  <span style={{ textDecoration: 'underline' }}>
                    mobilidade financeira
                  </span>
                </>
              )}
            </Typography>
            <Typography
              component="p"
              variant="body1"
              marginBottom={2}
              color={themes.authentication.palette.common.white}
            >
              {access ? Texts.DEFAULT_TEXT : Texts.DEFAULT_TEXT}
            </Typography>
            <List>
              {Object.keys(Products).map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    sx={{
                      width: 'auto',
                      padding: '8px 0px',
                      marginRight: '-8px',
                    }}
                  >
                    <ListItemAvatar sx={{ minWidth: 40 }}>
                      <Avatar
                        sx={{
                          backgroundColor: Products[item].color,
                        }}
                      >
                        <Icon
                          size={20}
                          name="logo-2nd"
                          viewBox="0 0 28 28"
                          format={IconFormat.SVG}
                        />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default Routine;
