import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLocalStorage } from '@2ndmarket/hooks';
import { AppReferenceProps } from '@2ndmarket/types';

import Box from '@mui/material/Box';

import Products from '../../helper/products';
import DrawerComponent from '../Drawer/Drawer';
import { LocalStorage } from '@2ndmarket/helpers';

const AppReference: React.FC<AppReferenceProps> = ({
  onClick,
  anchor,
}) => {
  const router = useRouter();
  const { redirect } = router.query;
  const [storedProduct, setStoredProduct] = useLocalStorage(
    LocalStorage.PRODUCT,
    Products.Bankai,
  );

  let product = storedProduct ?? Products.Bankai;

  useEffect(() => {
    if (redirect) {
      const selectdProduct = Object.values(Products).find(
        item => item.name === redirect,
      );

      if (selectdProduct) {
        setStoredProduct(selectdProduct);
      }
    }
  }, [redirect, setStoredProduct]);

  const childParent = (dataChild: object) => {
    return setStoredProduct(dataChild);
  };

  return (
    <Box
      tabIndex={0}
      display="flex"
      marginBottom={3}
      onClick={onClick}
      onKeyDown={onClick}
      role="presentation"
      alignItems="center"
      justifyContent="space-between"
    >
      <DrawerComponent
        anchor={anchor}
        product={product}
        products={Products}
        childToParentDrawer={childParent}
      />
    </Box>
  );
};

export default AppReference;
