import { useEffect, useState } from 'react';
import { ListProductProps } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {
  ButtonProduct,
  CheckboxProduct,
  ListItemButtonProduct,
} from './styles';
import { LocalStorage } from '@2ndmarket/helpers';

const ListProducts: React.FC<ListProductProps> = ({
  product,
  closeDrawer,
  childParentProduct,
}) => {
  const listProducts = product as object;

  const [close, setClosen] = useState(false);
  const [products, setProducts] = useState<object>();
  const [checked, setChecked] = useState([]) as any;
  const [selected, setSelected] = useState<number>();

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const product = Object.values(listProducts).find(
      (item, index) => index === value,
    );

    setSelected(value);
    setProducts(product);
    setChecked(newChecked);

    if (localStorage.getItem(LocalStorage.PRODUCT)) {
      localStorage.removeItem(LocalStorage.PRODUCT);
    }

    localStorage.setItem(
      LocalStorage.PRODUCT,
      JSON.stringify(product),
    );

    childParentProduct(product);
    closeDrawer(close);
  };

  useEffect(() => {
    if (localStorage.getItem(LocalStorage.PRODUCT)) {
      const storage = localStorage.getItem(LocalStorage.PRODUCT);
      const product = JSON.parse(storage as string);

      const index = Object.values(listProducts).findIndex(
        item => item.key === product.key,
      );

      const checked = [index];

      setSelected(index);
      setProducts(product);
      setChecked(checked);
    }
  }, [listProducts]);

  return (
    <Box display="flex" flexDirection="column">
      <List
        aria-label="contacts"
        sx={{
          gap: 2,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {Object.values(listProducts).map(
          (product, index: number) => {
            const labelId = `checkbox-list-label-${index}`;

            return product.active == true ? (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  gap: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <ListItemButtonProduct
                  dense
                  role={undefined}
                  selected={selected === index}
                  onClick={handleToggle(index)}
                >
                  <CheckboxProduct
                    tabIndex={-1}
                    disableRipple
                    checked={checked.indexOf(index) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      bgcolor: product.color,
                    }}
                  >
                    <Icon
                      size={20}
                      name="logo-2nd"
                      viewBox="0 0 28 28"
                      format={IconFormat.SVG}
                    />
                  </Avatar>
                  <ListItemText
                    id={labelId}
                    primary={product.name}
                    color={
                      themes.authentication.palette.grey[600]
                    }
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: 400,
                        fontSize: 16,
                      },
                    }}
                    sx={{ fontWeight: 400, fontSize: 16 }}
                  />
                </ListItemButtonProduct>
              </ListItem>
            ) : (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  gap: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <ListItemButtonProduct
                  dense
                  sx={{ cursor: 'default' }}
                >
                  <CheckboxProduct disabled />
                  <Avatar
                    sx={{
                      bgcolor:
                        themes.authentication.palette.grey[300],
                      width: 50,
                      height: 50,
                    }}
                  >
                    <Icon
                      size={20}
                      name="logo-2nd"
                      viewBox="0 0 28 28"
                      format={IconFormat.SVG}
                    />
                  </Avatar>
                  <ListItemText
                    primary={product.name}
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: 400,
                        fontSize: 16,
                      },
                    }}
                    color={
                      themes.authentication.palette.grey[600]
                    }
                    sx={{ fontWeight: 400, fontSize: 16 }}
                  />
                  <ButtonProduct variant="contained" disabled>
                    Em breve
                  </ButtonProduct>
                </ListItemButtonProduct>
              </ListItem>
            );
          },
        )}
      </List>
    </Box>
  );
};

export default ListProducts;
