import Grid from '@mui/material/Grid';

import Loader from '../Loader';

interface LoadingProps {
  loading: boolean;
}

const LoadingSection: React.FC<LoadingProps> = ({ loading }) => {
  return (
    <Grid
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      position="absolute"
      top={0}
    >
      <Loader loaderState={true} text="Carregando..." />
    </Grid>
  );
};

export default LoadingSection;
