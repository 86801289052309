import { styled } from '@mui/material/styles';
import { themes } from '@2ndmarket/components';

import Box from '@mui/material/Box';

export const BoxLoader = styled(Box)({
  padding: 35,
  width: '152px',
  height: '152px',
  borderRadius: '50%',
  position: 'relative',
  backgroundColor: themes.authentication.palette.grey[400],
  '@keyframes anim_one': {
    '0%': {
      top: 0,
      left: 0,
    },
    '25%': {
      top: 0,
      left: 'calc(50% - 50px)',
    },
    '50%': {
      top: 'calc(50% - 50px)',
      left: 'calc(50% - 50px)',
      height: '22px',
      width: '22px',
      background: themes.authentication.palette.grey[100],
    },
    '75%': {
      top: 'calc(50% - 50px)',
      left: 0,
    },
    '100%': {
      top: 0,
      left: 0,
    },
  },
  '@keyframes anim_two': {
    '0%': {
      left: 'calc(50% - 50px)',
      top: 'calc(50% - 50px)',
      height: '22px',
      width: '22px',
    },
    '25%': {
      left: 0,
      top: 'calc(50% - 50px)',
    },
    '50%': {
      left: 0,
      top: 0,
      height: '22px',
      width: '22px',
      background: themes.authentication.palette.grey[100],
    },
    '75%': {
      left: 'calc(50% - 50px)',
      top: 0,
    },
    '100%': {
      left: 'calc(50% - 50px)',
      top: 'calc(50% - 50px)',
      height: '22px',
      width: '22px',
    },
  },
  '& .square': {
    margin: 50,
    borderRadius: '0%',
    position: 'absolute',
    boxShadow: '0 0 12px rgba(0,0,0,0.1)',
    '&.first': {
      width: '22px',
      height: '22px',
      animation: 'anim_one 2s linear infinite',
      border: `4px solid ${themes.authentication.palette.primary.main}`,
    },
    '&.second': {
      width: '22px',
      height: '22px',
      top: 'calc(100% - 100px)',
      left: 'calc(100% - 100px)',
      animation: 'anim_two 2s linear infinite',
      border: `4px solid ${themes.authentication.palette.primary.light}`,
    },
  },
});
