import { themes } from '@2ndmarket/components';
import {
  Names,
  Product,
  ProductsObject,
} from '@2ndmarket/helpers';

const Products: ProductsObject = {
  Dashboard: {
    color: themes.authentication.colors.products.authentication,
    key: Product.DASHBOARD,
    name: Names.dashboard,
    destination:
      process.env.NEXT_PUBLIC_DASHBOARD_URL + '/redirect',
    active: true,
  },
  Bankai: {
    color: themes.authentication.colors.products.bankai,
    key: Product.BANKAI,
    name: Names.bankai,
    destination:
      process.env.NEXT_PUBLIC_BANKAI_URL + '/redirect',
    active: true,
  },
  Braex: {
    color: themes.authentication.colors.products.braex,
    key: Product.BRAEX,
    name: Names.braex,
    destination: process.env.NEXT_PUBLIC_BRAEX_URL + '/redirect',
    active: true,
  },
  Tokenaim: {
    color: themes.authentication.colors.products.tokenaim,
    key: Product.TOKENAIM,
    name: Names.tokenaim,
    destination: process.env.NEXT_PUBLIC_TOKENAIM_URL,
    active: false,
  },
};

export default Products;
