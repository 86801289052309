import { useState } from 'react';
import { Icon, themes } from '@2ndmarket/components';
import { AppReferenceProps } from '@2ndmarket/types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip, {
  TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

import ListProducts from '../ListProduct/ListProducts';

import { DrawerHeader } from './styles';

const DrawerComponent: React.FC<AppReferenceProps> = ({
  anchor,
  product,
  products,
  childToParentDrawer,
}) => {
  const [open, setOpen] = useState(false);
  const [drawer, setDrawer] = useState(anchor);

  const toogleDrawer = (open: boolean) => () => {
    anchor = 'right';

    setDrawer(anchor);
    setOpen(open);
  };

  const childToParent = (dataChild: object) => {
    return childToParentDrawer(dataChild);
  };

  const ToolTipAuth = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ),
  )({
    [`& .${tooltipClasses.tooltip}`]: {
      marginBottom: '0 !important',
      backgroundColor:
        themes.authentication.palette.primary.main,
    },
  });

  return (
    <>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={toogleDrawer(true)}
        justifyContent="space-between"
      >
        <Stack
          spacing={1}
          display="flex"
          direction="row"
          alignItems="center"
        >
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bgcolor: product.color,
            }}
          >
            <Icon name="2nd" size={20} />
          </Avatar>
          <div>
            <Typography
              component="p"
              variant="body2"
              color="text.disabled"
            >
              Login:
            </Typography>
            <Typography
              component="span"
              variant="caption"
              color="text.primary"
            >
              {product.name}
            </Typography>
          </div>
        </Stack>
        <Stack display="flex" direction="row">
          <ToolTipAuth
            title="Trocar"
            placement="top"
            className="tooltip-authentication"
            sx={{
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor:
                  themes.authentication.palette.grey[200],
              },
            }}
          >
            <IconButton>
              <Icon
                size={20}
                name="change"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
          </ToolTipAuth>
        </Stack>
      </Box>
      <Drawer
        open={open}
        anchor={drawer}
        role="presentation"
        onClose={toogleDrawer(false)}
      >
        <DrawerHeader>
          <Stack
            gap={6}
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Icon
                size={15}
                name="close"
                color={themes.authentication.palette.grey[300]}
              />
            </IconButton>
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
            >
              Selecione um produto
            </Typography>
          </Stack>
        </DrawerHeader>
        <ListProducts
          product={products}
          closeDrawer={setOpen}
          childParentProduct={childToParent}
        />
      </Drawer>
    </>
  );
};

export default DrawerComponent;
