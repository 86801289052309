import { themes } from '@2ndmarket/components';
import { ExternalUrls } from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Copyright = (props: any) => {
  return (
    <Box
      height={64}
      textAlign="center"
      alignItems="center"
      display="inline-flex"
      alignContent="center"
      justifyContent="center"
      width="100%"
      mt={4}
      sx={{
        backgroundColor: themes.authentication.palette.grey[100],
      }}
    >
      <Box>
        <Typography
          {...props}
          align="center"
          variant="body2"
          component="span"
          color="text.disabled"
        >
          {'Copyright © '}
          <Link
            target="_blank"
            color="primary.main"
            href={ExternalUrls.SECOND_MARKET}
          >
            2ND Market
          </Link>{' '}
          {new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
  );
};

export default Copyright;
