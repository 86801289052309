import { useState, useEffect } from 'react';

import { HttpError } from '@2ndmarket/helpers';
import { themes } from '@2ndmarket/components';
import { FormLoginCodeProps } from '@2ndmarket/types';
import { userRequestToken } from '@2ndmarket/services';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ResendCode: React.FC<FormLoginCodeProps> = ({
  email,
  setDisabled,
}) => {
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);

  var timeToShow = `${minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;

  const reset = () => {
    const time = 120;
    setDisabled({
      timeToShow: time,
    });

    userRequestToken(email)
      .then(() => {
        setMinutes(2);
        setSeconds(0);
      })
      .catch((error: HttpError) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearTimeout(interval);
          setDisabled({
            timeToShow: 0,
          });
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearTimeout(interval);
  }, [minutes, seconds, setDisabled]);

  return minutes === 0 && seconds === 0 ? (
    <Button
      fullWidth
      onClick={reset}
      color="secondary"
      variant="contained"
      sx={{
        zIndex: 1,
        marginTop: 1,
        boxShadow: 'none',
        position: 'relative',
      }}
    >
      <Typography component="span" variant="body1">
        Reenviar código
      </Typography>
    </Button>
  ) : (
    <Button
      disabled
      fullWidth
      color="secondary"
      variant="contained"
      sx={{
        zIndex: 2,
        marginTop: 1,
        position: 'relative',
        backgroundColor: `${themes.authentication.palette.grey[200]} !important`,
      }}
    >
      <Typography
        component="span"
        variant="body1"
        color="text.disabled"
      >
        Reenviar código em {timeToShow}
      </Typography>
    </Button>
  );
};

export default ResendCode;
