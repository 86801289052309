import { useEffect, useState } from 'react';

import { LoaderProps } from '@2ndmarket/types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { BoxLoader } from './styles';

const Loader: React.FC<LoaderProps> = ({
  text,
  title,
  subTitle,
  loaderState,
}) => {
  const [loaderShow, setLoaderShow] = useState(loaderState);

  useEffect(() => {
    setLoaderShow(loaderState);
  }, [loaderState]);

  return (
    <>
      {loaderShow && (
        <Grid
          item
          gap={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          zIndex={1}
          position="absolute"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <BoxLoader>
              <div className="square first" />
              <div className="square second" />
            </BoxLoader>
          </Box>
          <Typography
            component="h2"
            variant="body1"
            color="text.secondary"
          >
            {title}
          </Typography>
          <Typography
            component="h3"
            variant="body1"
            color="text.secondary"
          >
            {subTitle}
          </Typography>
          <Typography
            component="p"
            variant="body1"
            color="text.secondary"
          >
            {text}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Loader;
