import { yupPt, validateEmail } from '@2ndmarket/helpers';

const schema = yupPt.object().shape({
  email: yupPt
    .string()
    .max(75)
    .test('email', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'E-mail é obrigatório',
        });
      }

      if (!validateEmail(value as string)) {
        return createError({
          message: 'E-mail inválido',
        });
      }

      return true;
    }),
});

const defaultValues = {
  email: '',
};

export { schema, defaultValues };
