import { SEO } from '@2ndmarket/components';

interface Props {
  title: string;
  description: string;
}

const AuthSEO: React.FC<Props> = ({ title, description }) => {
  const url = 'https://auth.2ndmarket.com.br';

  const params = {
    appName: '2ND Market',
    title: title,
    description: description,
    image: url + '/banner.jpg',
    imageWidth: '1080',
    imageHeight: '1080',
    url: url,
    themeColor: '#313EE2',
  };

  return <SEO {...params}></SEO>;
};

export default AuthSEO;
