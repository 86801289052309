import { DialogProps } from '@2ndmarket/types';
import { themes } from '@2ndmarket/components';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const ModalDialog: React.FC<DialogProps> = ({
  onClose,
  errorMessage,
  errorTitle,
  error,
}) => {
  const defaultErrorTitle = 'Falha de login';

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <Grid paddingX={2} paddingY={3}>
        <DialogTitle
          variant="h2"
          component="h2"
          color="text.primary"
        >
          {errorTitle ? errorTitle : defaultErrorTitle}
        </DialogTitle>
        {(error || errorMessage) && (
          <DialogContent>
            <Typography
              component="p"
              variant="body1"
              color="text.secondary"
            >
              {errorMessage}
            </Typography>
          </DialogContent>
        )}
        <DialogActions sx={{ paddingX: 3, mt: 15 }}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => onClose()}
            sx={{
              boxShadow: 'none',
              color: `${themes.authentication.palette.primary.main} !important`,
              backgroundColor: `${themes.authentication.palette.grey[200]} !important`,
            }}
          >
            Entendi
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ModalDialog;
