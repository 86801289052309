import { yupPt } from '@2ndmarket/helpers';

const schema = yupPt.object().shape({
  code: yupPt
    .string()
    .test('code', '', (value, { createError }) => {
      if (value === undefined) {
        return false;
      }

      if (value === '') {
        return createError({
          message: 'Código é obrigatório',
        });
      }

      return true;
    })
    .min(6, 'Código inválido'),
});

const defaultValues = {
  code: '',
};

export { schema, defaultValues };
